import React from "react";
import { RequestIcon, AddIcon, MoreIcon } from "../../assets/assets";
import "./NavigationBar.css";

export default function NavigationBar(props) {
  return (
    <div
      data-cy="bottom-nav-bar"
      className={`nav-bar-width nav-bar-box-shadow font-family-bariol-regular padding-left-larger padding-right-larger padding-top-default padding-bottom-default max-height-64px ${
        props.menu.length <= 2
          ? "flex-justify-content-space-around"
          : "flex-justify-content-space-between"
      } ${props.className ? props.className : ""} ${
        props.removerBorder ? "" : "large-screen-border-for-navigation-bar"
      }`}
    >
      {props.menu.includes("request") && (
        <div
          data-cy="bottom-nav-bar-request"
          className=" flex-direction-column flex-center-children-vertically cursor-pointer"
          onClick={() => {
            props.setSelected("request");
          }}
        >
          <RequestIcon
            color={props.selected === "request" ? "#00a000" : "#4E4E4E"}
          />
          <div
            className={`${
              props.selected === "request" ? "font-color-primary " : ""
            } padding-top-default`}
          >
            Requests
          </div>
        </div>
      )}

      {props.menu.includes("create") && (
        <div
          data-cy="bottom-nav-bar-create"
          className=" flex-direction-column flex-center-children-vertically cursor-pointer"
          onClick={() => {
            props.setSelected("create");
          }}
        >
          <AddIcon
            color={props.selected === "create" ? "#00a000" : "#4E4E4E"}
          />
          <div
            className={`${
              props.selected === "create" ? "font-color-primary " : ""
            } padding-top-default`}
          >
            Create request
          </div>
        </div>
      )}

      {props.menu.includes("more") && (
        <div
          data-cy="bottom-nav-bar-more"
          className=" flex-direction-column flex-center-children-vertically cursor-pointer"
          onClick={() => {
            props.setSelected("more");
          }}
        >
          <MoreIcon color={props.selected === "more" ? "#00a000" : "#4E4E4E"} />
          <div
            className={`${
              props.selected === "more" ? "font-color-primary " : ""
            } padding-top-default`}
          >
            More
          </div>
        </div>
      )}
    </div>
  );
}
