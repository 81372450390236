const corporateReducer = (
  state = {
    loading: false,
    checkups: null,
    downloadableURLs: null,
    selectedRequest: null
  },
  action
) => {
  switch (action.type) {
    case "SET_CORPORATE_LOADING":
      return {
        ...state,
        loading: action.payload.loading
      };

    case "SET_CORPORATE_CHECKUPS_DATA":
      return {
        ...state,
        checkups: action.payload.data
      };

    case "SET_SELECTED_CHECKUP_DATA":
      return {
        ...state,
        selectedRequest: action.payload.data
      };

    case "SET_CHECKUP_DOWNLOADABLE_URL":
      return {
        ...state,
        downloadableURLs: {
          ...state.downloadableURLs,
          ...action.payload.data
        }
      };

    case "RESET_SELECTED_REQUEST":
      return {
        ...state,
        selectedRequest: null
      };

    case "RESET":
      return {
        loading: false,
        checkups: null,
        downloadableURLs: null,
        selectedRequest: null
      };

    default:
      return state;
  }
};
export default corporateReducer;
