import React from "react";
import SliderButton from "../../components/SliderButton/SliderButton";

export default function Test() {
  return (
    <div>
      <SliderButton
        completed={(data) => {
          console.log(data, "from slider");
        }}
        setCompleted={false}
      />
    </div>
  );
}
