import React, { useEffect, useState } from "react";
import ConditionalRender from "../../front-end-global-components/components/ConditionalRender/ConditionalRender";
import Header from "../../front-end-global-components/components/Header/Header";
import { OptionIcon } from "../../assets/assets";
import RoundedProfilePicture from "../../front-end-global-components/components/RoundedProfilePicture/RoundedProfilePicture";
import { connect } from "react-redux";
import { connectionActions } from "../../redux/sagas/connectionSaga";
import Button from "../../front-end-global-components/components/Button/Button";

function AddClinic(props) {
  const [selectedClinic, setSelectedClinics] = useState(null);
  useEffect(() => {
    props.getClinicBrands();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="inherit-parent-height flex-place-children-page-center">
      <div className="inherit-parent-height inherit-parent-width large-screen-bg-color large-screen-height large-screen-width large-screen-border">
        <Header
          removeResponsive={true}
          title="Add Partnered clinics"
          backButtonOnClick={() => {
            props.navigate("/settings");
          }}
          supportIconOnClick={() => {}}
        />
        <div className="remaining-body-height flex-direction-column flex-justify-content-space-between">
          <div className="inherit-parent-height padding-larger">
            <ConditionalRender condition={props.connection.loading === true}>
              <AddClinicSuspense />
            </ConditionalRender>
            <ConditionalRender condition={props.connection.loading === false}>
              <div className=" inherit-parent-height flex-direction-column flex-justify-content-space-between">
                <div>
                  {props.connection.clinicBrands &&
                    Object.values(props.connection.clinicBrands).map(
                      (data, index) => (
                        <div key={index} className=" padding-bottom-larger">
                          <PartneredClinicCard
                            box={true}
                            data={data}
                            connection={props.connection}
                            showOption={false}
                            selected={
                              selectedClinic &&
                              selectedClinic.documentId === data.documentId
                                ? true
                                : false
                            }
                            CardOnClick={() => {
                              setSelectedClinics(data);
                            }}
                          />
                        </div>
                      )
                    )}
                </div>
                <Button
                  data-cy="add-partnered-clinic-button"
                  boxShadow={false}
                  //   loading={props.logoutButtonLoading}
                  className=" font-family-gilroy-medium"
                  variant="primary"
                  text="Add clinic selected"
                  disabled={selectedClinic ? false : true}
                  type="button"
                  onClick={() => {
                    props.createCorporatePackageRequest({
                      clinicBrandId: selectedClinic.documentId,
                      clinicName: selectedClinic.name
                    });
                    props.navigate("/settings");
                  }}
                />
              </div>
            </ConditionalRender>
          </div>
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = function (state) {
  return {
    connection: state.connection
  };
};

const mapDispatchToProps = function () {
  return {
    getClinicBrands: () => connectionActions.getClinicBrands(),
    createCorporatePackageRequest: (data) =>
      connectionActions.createCorporatePackage(data)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddClinic);

const AddClinicSuspense = () => {
  return (
    <div className=" inherit-parent-height" data-cy="view-filled-form">
      {[...Array(9)].map((data, index) => (
        <div
          key={index}
          className=" shimmer width-90-percent padding-larger border-radius-default margin-bottom-large"
        />
      ))}
    </div>
  );
};

const PartneredClinicCard = (props) => {
  return (
    <div className=" inherit-parent-width display-flex flex-center-children-vertically flex-justify-content-space-between">
      <div
        data-cy={`${props.data.documentId}-card`}
        className=" inherit-parent-width display-flex flex-center-children-vertically"
        onClick={props.CardOnClick}
      >
        {props.box && (
          <div
            className={`${
              props.selected === true ? "background-color-primary" : ""
            } height-width-16px border-1px-e5e5e5 border-radius-default margin-right-large`}
          />
        )}

        <RoundedProfilePicture
          size="medium"
          userType="corporate"
          removeResponsive={true}
          src={
            props.connection?.clinicBrandLogo &&
            props.connection?.clinicBrandLogo[props.data?.documentId]
          }
        />
        <div className=" padding-left-large">{props.data.name}</div>
      </div>
      {props.showOption && (
        <div className="" onClick={props.onOptionClick}>
          <OptionIcon />
        </div>
      )}
    </div>
  );
};
