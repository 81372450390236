import React, { useEffect, useState } from "react";
import Header from "../../front-end-global-components/components/Header/Header";
import Modal from "../../front-end-global-components/components/Modal/Modal";
import RoundedProfilePicture from "../../front-end-global-components/components/RoundedProfilePicture/RoundedProfilePicture";
import arrow from "../../front-end-global-components/assets/DropDownArrow.svg";
import setting from "../../assets/icons/Settings.svg";
import { connect } from "react-redux";
import ProfileCards from "../../components/ProfileCards/ProfileCards";
import "./Home.css";
import { connectionActions } from "../../redux/sagas/connectionSaga";
import { isValidArray, isValidObject } from "../../utils/validators";
import wait from "../../assets/icons/waitingIcon.svg";
import Button from "../../front-end-global-components/components/Button/Button";
import warning from "../../front-end-global-components/assets/warning_icon.svg";
import ConditionalRender from "../../front-end-global-components/components/ConditionalRender/ConditionalRender";
import NavigationBar from "../../components/NavigationBar/NavigationBar";
import InputBox from "../../front-end-global-components/components/InputBox/InputBox";
import ChipInputBox from "../../front-end-global-components/components/ChipInputBox/ChipInputBox";
import CheckBox from "../../front-end-global-components/components/CheckBox/CheckBox";
import { HealthCheckupTimeline } from "../../front-end-global-components/components/HealthCheckupTimeline/HealthCheckupTimeline";
import { corporateActions } from "../../redux/sagas/corporateSaga";
import empty from "../../front-end-global-components/assets/images/fallback.svg";
import { PuzzlePieceIcon } from "../../assets/assets";

function Home(props) {
  const [showModal, setShowModal] = useState(false);
  const [verificationStatus, setVerificationStatus] = useState("");
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(false);
  const [navigation, setNavigation] = useState("request");
  const [isDeleteData, setIsDeleteData] = useState(false);
  const [showAddEmployeeModal, setShowAddEmployeeModal] = useState(false);
  const [createRequestFormData, setCreateRequestFormData] = useState({
    description: "",
    shareReportWithUser: false
  });
  const [currentStaffData, setCurrentStaffData] = useState({
    phoneNumber: "+91"
  });
  const [currentEmployeeData, setCurrentEmployeeData] = useState({});

  useEffect(() => {
    if (!isValidObject(props.connection.currentConnection)) {
      return;
    }
    if (!Object.keys(props.connection.currentConnection).includes("verified")) {
      setVerificationStatus("notVerified");
      return;
    }
    if (props.connection.currentConnection.verified === false) {
      setVerificationStatus("failed");
    } else if (props.connection.currentConnection.verified === true) {
      setVerificationStatus("verified");
    }
  }, [props.connection.data, props.connection.currentConnection]);

  useEffect(() => {
    if (isDeleteData) {
      setCurrentEmployeeData(currentEmployeeData);
    }
    // eslint-disable-next-line
  }, [isDeleteData]);

  const isRequestExpired = (timestamp) => {
    const day = Math.round((timestamp - +new Date()) / 86400000);
    if (day <= 0) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <ConditionalRender
        condition={verificationStatus && verificationStatus === "verified"}
      >
        <>
          <div
            data-cy="home-page-content"
            className="inherit-parent-height flex-place-children-page-center"
          >
            <div className=" inherit-parent-height inherit-parent-width large-screen-bg-color large-screen-height large-screen-width large-screen-border">
              <Header
                removeResponsive={true}
                userType="corporate"
                headerPositionLeft={true}
                hideBackButton={true}
                maxStepperCount={0}
                currentStepperValue={0}
                supportIconOnClick={() => {}}
              />
              <div
                className={`remaining-body-height-home flex-direction-column flex-justify-content-space-between`}
              >
                <ConditionalRender condition={navigation === "request"}>
                  <div className="padding-left-larger padding-right-larger padding-bottom-large">
                    <CorporateSwitcher
                      data={
                        props.connection.currentConnection &&
                        props.connection.currentConnection
                      }
                      connection={props.connection}
                      dropDownOnClick={() => {
                        setShowModal(true);
                      }}
                      settingOnClick={() => {
                        props.navigate("/settings");
                      }}
                    />
                  </div>
                  <div className="container-height overflow-y-scroll hide-scrollbar">
                    <ConditionalRender
                      condition={
                        isValidArray(props.corporate.checkups) &&
                        isValidObject(props.connection.corporatePackages) &&
                        isValidObject(
                          Object.values(
                            props.connection.corporatePackages
                          ).find(
                            (element) =>
                              element.corporateBrandId ===
                                props.connection.currentConnection.brandId &&
                              element.accepted === true
                          )
                        )
                      }
                    >
                      <HealthCheckupTimeline
                        cardType="corporate"
                        filter={["active", "completed", "expired"]}
                        checkupsData={
                          props.corporate.checkups &&
                          props.corporate.checkups.filter(
                            (data) =>
                              data.corporateId ===
                              props.connection?.currentConnection?.connectionId
                          )
                        }
                        connection={props.connection}
                        createNewRequestOnClick={() => {
                          setNavigation("create");
                        }}
                        cardOnClick={(data) => {
                          if (
                            !isRequestExpired(data.expiringAt) ||
                            (isRequestExpired(data.expiringAt) &&
                              data.status === "COMPLETED")
                          ) {
                            props.setSelectedRequest(data.requestId);
                            props.navigate("/request");
                          }
                        }}
                      />
                    </ConditionalRender>

                    <ConditionalRender
                      condition={
                        !props.corporate.loading &&
                        !props.connection.loading &&
                        isValidObject(props.connection.corporatePackages) &&
                        isValidObject(
                          Object.values(
                            props.connection.corporatePackages
                          ).find(
                            (element) =>
                              element.corporateBrandId ===
                                props.connection.currentConnection.brandId &&
                              element.accepted === true
                          )
                        ) &&
                        !isValidArray(props.corporate.checkups)
                      }
                    >
                      <NoRequestAvailable
                        cardType={"corporate"}
                        createNewRequestOnClick={() => {
                          setNavigation("create");
                        }}
                      />
                    </ConditionalRender>
                    <ConditionalRender
                      condition={
                        props.corporate.loading || props.connection.loading
                      }
                    >
                      <TimelineSuspense />
                    </ConditionalRender>
                    <ConditionalRender
                      condition={
                        !props.corporate.loading ||
                        !props.connection.loading ||
                        (isValidObject(props.connection.corporatePackages) &&
                          isValidObject(
                            Object.values(
                              props.connection.corporatePackages
                            ).find(
                              (element) =>
                                element.corporateBrandId ===
                                  props.connection.currentConnection.brandId &&
                                element.accepted === true
                            )
                          )) ||
                        !isValidObject(props.connection.corporatePackages)
                      }
                    >
                      <NoPartneredClinic
                        removeButton={
                          props.connection?.currentConnection?.userType !==
                          "owner"
                        }
                        addClinicOnClick={() => {
                          props.navigate("/addClinic");
                        }}
                      />
                    </ConditionalRender>
                  </div>
                </ConditionalRender>
                <ConditionalRender condition={navigation === "create"}>
                  <form
                    onChange={(event) => {
                      if (event.target.name === "shareReportWithUser") {
                        setCreateRequestFormData({
                          ...createRequestFormData,
                          [event.target.name]: event.target.checked
                        });
                      } else {
                        setCreateRequestFormData({
                          ...createRequestFormData,
                          [event.target.name]: event.target.value
                        });
                      }
                    }}
                    onSubmit={(event) => {
                      event.preventDefault();
                      props.createHealthRequest({
                        branchId:
                          props.connection.currentConnection.connectionId,
                        staffId:
                          props.profile.data &&
                          Object.keys(props.profile.data)[0],
                        phoneNumbers:
                          isValidObject(currentEmployeeData) &&
                          Object.keys(currentEmployeeData),
                        healthCheckupDetails: createRequestFormData
                      });
                      setCurrentStaffData({
                        phoneNumber: "+91"
                      });
                      setCreateRequestFormData({
                        description: "",
                        shareReportWithUser: false
                      });
                      setCurrentEmployeeData({});
                      setNavigation("request");
                    }}
                    className="inherit-parent-height padding-horizontal-larger"
                  >
                    <h1 className="font-weight-normal padding-top-larger font-family-gilroy-medium font-size-small font-color-secondary inherit-parent-width text-align-center">
                      CREATE & SEND REQUEST
                    </h1>
                    <InputBox
                      className="inherit-parent-width border-radius-default margin-vertical-larger"
                      name="description"
                      removeResponsive={true}
                      label="Description"
                      value={createRequestFormData.description}
                      type="text"
                      required
                      data-cy="description-field"
                    />
                    <ChipInputBox
                      className="inherit-parent-width border-radius-default margin-vertical-larger"
                      data-cy="add-employees-field"
                      name="employees"
                      label="Add employees"
                      removeResponsive={true}
                      notes="Type and press Enter to Add employees"
                      maxLength={0}
                      chips={
                        isValidObject(currentEmployeeData)
                          ? Object.values(currentEmployeeData).map((staff) => {
                              return {
                                display: staff.phoneNumber,
                                id: staff.phoneNumber
                              };
                            })
                          : []
                      }
                      onChipDelete={(profileId) => {
                        setIsDeleteData(delete currentEmployeeData[profileId]);
                      }}
                      reDirectOnClick={() => {
                        setShowAddEmployeeModal(true);
                      }}
                    />
                    <div className="inherit-parent-width padding-vertical-large text-align-left font-size-medium">
                      <CheckBox
                        name="shareReportWithUser"
                        label="Send a final report copy to the employee"
                      />
                    </div>
                    <Button
                      className="inherit-parent-width border-radius-default margin-vertical-larger"
                      data-cy="send-request-button"
                      loading={props.corporate.loading}
                      type="submit"
                      text="Send request"
                      disabled={
                        !(
                          createRequestFormData.description &&
                          isValidObject(currentEmployeeData) &&
                          Object.keys(currentEmployeeData)
                        )
                      }
                      boxShadow={false}
                    />
                  </form>
                </ConditionalRender>
                <ConditionalRender condition={navigation === "more"}>
                  <div>More</div>
                </ConditionalRender>
                {isValidObject(props.connection.corporatePackages) &&
                  isValidObject(
                    Object.values(props.connection.corporatePackages).find(
                      (element) =>
                        element.corporateBrandId ===
                          props.connection.currentConnection.brandId &&
                        element.accepted === true
                    )
                  ) && (
                    <NavigationBar
                      className=" background-white large-screen-width"
                      menu={["request", "create"]}
                      setSelected={setNavigation}
                      selected={navigation}
                    />
                  )}
              </div>
              <Modal
                show={showModal}
                canIgnore={true}
                onClose={() => {
                  setShowModal(!showModal);
                }}
                position={
                  props.windowInnerWidth > 576
                    ? ""
                    : "position-fixed bottom-0 left-0 right-0"
                }
                width="inherit-parent-width"
                maxWidth={props.windowInnerWidth < 576 ? "" : "max-width-500px"}
                background="false"
                boxShadow="false"
                borderRadius="false"
                height="height-fit-to-content"
              >
                <div
                  data-cy="switcher-modal"
                  className={`background-white padding-left-larger padding-bottom-larger padding-top-larger ${
                    props.windowInnerWidth < 576
                      ? "border-radius-top-default"
                      : "border-radius-default"
                  } box-shadow-default font-family-gilroy-regular font-color-secondary`}
                >
                  <div
                    className={`${
                      props.connection.data && props.connection.data.length > 3
                        ? "height-224px overflow-y-scroll overflow-x-hidden"
                        : ""
                    }`}
                  >
                    {props.connection.currentConnection &&
                      props.connection.data &&
                      props.connection.data.map((data, index) => (
                        <ProfileCards
                          connection={props.connection}
                          selected={
                            props.connection.currentConnection.connectionId ===
                            data.connectionId
                          }
                          key={index}
                          data={data}
                          onCardClick={() => {
                            if (
                              props.connection.currentConnection
                                .connectionId !== data.connectionId
                            ) {
                              props.setCurrentConnection(data);
                            }
                          }}
                        />
                      ))}
                    <div
                      className={`${
                        props.connection.data &&
                        props.connection.data.length > 3
                          ? "padding-top-default position-sticky bottom-0 background-color-white"
                          : ""
                      }`}
                    >
                      <ProfileCards
                        type="add"
                        text="Add Corporate"
                        addOnClick={() => {
                          props.navigate("/create");
                        }}
                      />
                    </div>
                  </div>
                </div>
              </Modal>
              {/* ADD EMPLOYEE MODAL */}
              <Modal
                show={showAddEmployeeModal}
                canIgnore={true}
                onClose={() => {
                  setShowAddEmployeeModal(!showAddEmployeeModal);
                  setCurrentStaffData({
                    phoneNumber: "+91"
                  });
                }}
                position={
                  props.windowInnerWidth > 576
                    ? ""
                    : "position-fixed bottom-0 left-0 right-0"
                }
                width="inherit-parent-width"
                maxWidth={props.windowInnerWidth < 576 ? "" : "max-width-500px"}
                background="false"
                boxShadow="false"
                borderRadius="false"
                height="height-fit-to-content"
              >
                <form
                  data-cy="add-employee-form"
                  className={`background-white padding-larger ${
                    props.windowInnerWidth < 576
                      ? "border-radius-top-default"
                      : "border-radius-default"
                  } box-shadow-default font-family-gilroy-regular font-color-secondary`}
                  onChange={(event) =>
                    setCurrentStaffData({
                      ...currentStaffData,
                      [event.target.name]: event.target.value
                    })
                  }
                  onSubmit={(event) => {
                    event.preventDefault();
                    setCurrentEmployeeData({
                      ...currentEmployeeData,
                      [currentStaffData.phoneNumber]: currentStaffData
                    });
                    setCurrentStaffData({
                      phoneNumber: "+91"
                    });
                    setShowAddEmployeeModal(!showAddEmployeeModal);
                  }}
                >
                  <div className=" font-size-medium text-align-center padding-bottom-large">
                    Add employees
                  </div>
                  <div className=" font-size-small text-align-center ">
                    Employees can use all the features available
                  </div>
                  <div className="display-flex flex-grow-1 flex-align-items-center padding-bottom-larger cursor-pointer ">
                    <div className="flex-grow-1 flex-direction-column display-flex padding-left-medium">
                      <InputBox
                        data-cy="add-employee-phoneNumber-field"
                        className="inherit-parent-width margin-top-larger"
                        removeResponsive={true}
                        label="Phone number"
                        type="text"
                        autoFocus={true}
                        name="phoneNumber"
                        notes="Employees can use this phone number to log in with an OTP"
                        value={currentStaffData.phoneNumber}
                        isValidInput={(data) => {
                          setIsValidPhoneNumber(data);
                        }}
                      />
                    </div>
                  </div>
                  <div className="display-flex">
                    <Button
                      boxShadow={false}
                      data-cy="save-button"
                      loading={props.logoutButtonLoading}
                      className="margin-right-small font-family-gilroy-medium"
                      variant="primary"
                      text="Save"
                      disabled={!isValidPhoneNumber}
                      type="submit"
                    />
                    <Button
                      boxShadow={false}
                      className="margin-left-small font-family-gilroy-medium"
                      variant="secondary"
                      text="Close"
                      onClick={() => {
                        setShowAddEmployeeModal(!showAddEmployeeModal);
                        setCurrentStaffData({
                          phoneNumber: "+91"
                        });
                      }}
                      type="button"
                    />
                  </div>
                </form>
              </Modal>
            </div>
          </div>
        </>
      </ConditionalRender>
      <ConditionalRender
        condition={verificationStatus && verificationStatus !== "verified"}
      >
        <Verification
          status={verificationStatus}
          showHomeButton={
            props.connection.data && props.connection.data.length > 1
              ? true
              : false
          }
          tryAgainOnClick={() => {
            props.navigate("/create");
          }}
          homeOnClick={() => {
            props.setCurrentConnection(
              props.connection.data.filter((data) => data.verified === true)[0]
            );
          }}
        />
      </ConditionalRender>
    </>
  );
}

const mapStateToProps = function (state) {
  return {
    profile: state.profile,
    connection: state.connection,
    corporate: state.corporate
  };
};

const mapDispatchToProps = function () {
  return {
    setCurrentConnection: (data) =>
      connectionActions.setCurrentConnection(data),
    setSelectedRequest: (data) => corporateActions.setSelectedRequest(data),
    createHealthRequest: (data) => corporateActions.createHealthRequest(data)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);

const CorporateSwitcher = (props) => {
  return (
    <div className="inherit-parent-width flex-justify-content-space-between flex-center-children-vertically padding-vertical-large">
      <div
        data-cy="drop-down-button"
        className=" display-flex cursor-pointer"
        onClick={props.dropDownOnClick}
      >
        <div className=" padding-right-default">
          <RoundedProfilePicture
            size="small"
            userType="corporate"
            src={
              props.connection?.corporateBrandLogo &&
              props.connection?.corporateBrandLogo[props.data?.brandId]
            }
          />
        </div>
        <div className="padding-left-default flex-direction-column flex-center-children-horizontally">
          <div className=" display-flex flex-center-children-vertically">
            <div className=" font-size-large padding-right-default">
              {props.data?.companyName
                ? props.data.companyName
                : "All companies"}
            </div>
            <img src={arrow} alt="arrow" />
          </div>
          <ConditionalRender
            condition={typeof props.data.locality === "string"}
          >
            <div className=" font-size-small padding-top-small">
              {props.data?.locality ? props.data.locality : ""}
            </div>
          </ConditionalRender>
        </div>
      </div>
      <div
        data-cy="setting-icon"
        className="cursor-pointer"
        onClick={props.settingOnClick}
      >
        <img className="" src={setting} alt="setting" />
      </div>
    </div>
  );
};

function Verification(props) {
  return (
    <div className="inherit-parent-height flex-place-children-page-center">
      <div className="inherit-parent-height flex-place-children-page-center padding-larger inherit-parent-width large-screen-bg-color large-screen-height large-screen-width large-screen-border">
        {props.status === "failed" ? (
          <>
            <div className=" flex-center-children-horizontally">
              <img
                className=" height-width-32px margin-bottom-larger"
                src={warning}
                alt="waiting"
              />
            </div>
            <div className="text-align-center font-size-large font-family-gilroy-medium padding-horizontal-large">
              Verification failed
            </div>
            <div className=" text-align-center font-size-large padding-vertical-large">
              Please try again or contact our technical team
            </div>
            <Button
              type="button"
              className="margin-bottom-large"
              text="Try again"
              boxShadow={false}
              onClick={props.tryAgainOnClick}
            />
            {props.showHomeButton && (
              <Button
                type="button"
                variant="secondary"
                className="margin-bottom-large"
                text="Home"
                boxShadow={false}
                onClick={props.homeOnClick}
              />
            )}
            <div className="text-align-center  padding-vertical-large">
              <span className=" font-color-primary">Contact</span> our experts
              for help.
            </div>
          </>
        ) : (
          <>
            <div className=" flex-center-children-horizontally">
              <img
                className=" height-width-32px margin-bottom-larger"
                src={wait}
                alt="waiting"
              />
            </div>
            <div className="text-align-center font-size-large font-family-gilroy-medium padding-horizontal-large">
              Setup complete!
            </div>
            <div className=" text-align-center font-size-large padding-vertical-large">
              Please wait while our team verifies the information provide.
            </div>
            {props.showHomeButton ? (
              <Button
                type="button"
                className="margin-bottom-large"
                text="Home"
                boxShadow={false}
                onClick={props.homeOnClick}
              />
            ) : (
              <div className="text-align-center padding-vertical-large">
                You can <span className=" font-color-primary">Log in</span> once
                your account is verified
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

const NoRequestAvailable = (props) => {
  return (
    <div className="inherit-parent-height flex-direction-column padding-horizontal-larger flex-center-children-horizontally flex-center-children-vertically inherit-parent-width">
      <div className="padding-top-larger">
        <img height={148} width={120} src={empty} alt="empty" />
      </div>

      <div className=" font-family-gilroy-medium padding-top-larger">
        {props.cardType === "clinic"
          ? "No appointment available"
          : "No requests available."}
      </div>
      <div className=" text-align-center padding-top-larger">
        {props.cardType === "corporate"
          ? "Please create a new request to send to your employee(s) for their health checkup."
          : props.cardType === "clinic"
          ? "Once a patient books an appointment with your clinic, the checkup requests appear here."
          : " The checkup requests appear here once your employer sends a request"}
      </div>
      {props.cardType === "corporate" && (
        <Button
          data-cy="create-new-request-button"
          type="button"
          className="margin-top-larger"
          text="Create new request"
          onClick={props.createNewRequestOnClick}
          boxShadow={false}
        />
      )}
    </div>
  );
};

const NoPartneredClinic = (props) => {
  return (
    <div className="inherit-parent-height flex-direction-column padding-horizontal-larger flex-center-children-horizontally flex-center-children-vertically inherit-parent-width">
      <div className="padding-top-larger">
        <PuzzlePieceIcon height="32" width="32" />
      </div>

      <div className=" font-family-gilroy-medium padding-top-larger">
        Please add your partnered clinic
      </div>
      <div className=" text-align-center padding-top-larger">
        You will have to add your partnered clinic to create and send requests
      </div>
      {!props.removeButton && (
        <Button
          data-cy="create-new-request-button"
          type="button"
          className="margin-top-larger"
          text="Add clinic"
          onClick={props.addClinicOnClick}
          boxShadow={false}
        />
      )}
    </div>
  );
};

function TimelineSuspense() {
  return (
    <div className=" padding-left-larger padding-right-larger inherit-parent-width ">
      <div className="shimmer border-radius-default min-height-70px" />
      {[...Array(10).keys()].map((index) => (
        <div
          key={index}
          className="shimmer margin-top-larger border-radius-default min-height-70px"
        />
      ))}
    </div>
  );
}
