import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import FHIRRenderer from "../../front-end-global-components/components/FHIRRenderer/FHIRRenderer";
import PDFRenderer from "../../front-end-global-components/components/PDFRenderer/PDFRenderer";
import ConditionalRender from "../../front-end-global-components/components/ConditionalRender/ConditionalRender";
import Header from "../../front-end-global-components/components/Header/Header";
import { statusActions } from "../../redux/sagas/statusSaga";
import { isValidArray, isValidObject } from "../../utils/validators";

function ViewDocument(props) {
  const { documentId } = useParams();
  const [readOnly] = useState(true);
  const [jsonData, setJsonData] = useState(null);
  const [pdfArrayBuffer, setPdfArrayBuffer] = useState({});
  const [fileType, setFileType] = useState("");
  const [PDFfromFHIR, setPDFfromFHIR] = useState(null);
  const [isPdfLoading, setIsPdfLoading] = useState(false);

  const getPdfData = () => {
    if (
      isValidArray(props.corporate.checkups) &&
      props.corporate.checkups
        .flat()
        .some((doc) => doc.documentId === documentId) &&
      isValidObject(props.corporate?.downloadableURLs) &&
      typeof props.corporate?.downloadableURLs[documentId] === "string"
    ) {
      fetch(props.corporate?.downloadableURLs[documentId])
        .then(async (res) => {
          const url = res.url.split("?")[0];
          const lastElement = url.split(".").length - 1;
          setFileType(url.split(".")[lastElement]);
          if (url.split(".")[lastElement] === "json") {
            const response = await fetch(res.url, {
              method: "GET"
            });
            const text = await response.json();
            setJsonData(text);
          } else {
            setPdfArrayBuffer({
              ...pdfArrayBuffer,
              base: await res.arrayBuffer()
            });
          }
        })
        .catch((error) => {
          statusActions.setErrorStatus({
            custom: "custom",
            message: "Unable to download"
          });
          console.error(error);
        });
    }
  };

  useEffect(() => {
    if (!Object.keys(props.corporate?.downloadableURLs).includes(documentId)) {
      return props.navigate("/request");
    }
    if (typeof props.corporate?.downloadableURLs[documentId] === "string") {
      getPdfData();
    }
    // eslint-disable-next-line
  }, [props.corporate.downloadableURLs, documentId]);

  return (
    <>
      <div className="inherit-parent-height flex-place-children-page-center ">
        <div className="inherit-parent-height inherit-parent-width large-screen-bg-color large-screen-height large-screen-width large-screen-border">
          <Header
            removeResponsive={true}
            title="View document"
            backButtonOnClick={() => {
              if (PDFfromFHIR) {
                setPDFfromFHIR(null);
              } else {
                props.navigate("/request");
              }
            }}
            supportIconOnClick={() => {}}
          />
          <div className="remaining-body-height flex-center-children-horizontally position-relative inherit-parent-width overflow-y-scroll hide-scrollbar">
            <div className="inherit-parent-width max-width-500px flex-center-children-horizontally">
              <ConditionalRender
                condition={
                  isValidArray(props.corporate.checkups) &&
                  props.corporate.checkups
                    .flat()
                    .some((doc) => doc.documentId === documentId)
                }
              >
                {((fileType && fileType !== "json") || PDFfromFHIR) && (
                  <PDFRenderer
                    readOnly={readOnly}
                    pdfArrayBuffer={PDFfromFHIR && PDFfromFHIR}
                    // isPrescription={documentType === "prescription"}
                    saveWithBackground={false}
                    onPDFChange={() => {
                      if (isPdfLoading === false) {
                        setIsPdfLoading(true);
                      }
                    }}
                    onRender={() => {
                      if (isPdfLoading === true) {
                        setIsPdfLoading(false);
                      }
                    }}
                  />
                )}
                {fileType === "json" &&
                  jsonData !== null &&
                  PDFfromFHIR === null && (
                    <FHIRRenderer
                      data={jsonData}
                      documentOnClick={(document) => {
                        setPDFfromFHIR(document);
                      }}
                    />
                  )}
              </ConditionalRender>
              {!fileType && <ViewDocumentSuspense />}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = function (state) {
  return {
    corporate: state.corporate
  };
};

const mapDispatchToProps = function () {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewDocument);

function ViewDocumentSuspense() {
  return (
    <div className="inherit-parent-width margin-top-large">
      <div className=" flex-justify-content-space-between margin-large  flex-center-children-vertically">
        <div className=" flex-center-children-vertically">
          <div className="shimmer width-100px height-25px margin-right-default border-radius-default " />
        </div>
        <div className="shimmer width-100px height-25px margin-left-default border-radius-default" />
      </div>
      <div className=" flex-justify-content-space-between margin-top-larger  padding-horizontal-large flex-center-children-vertically margin-top-large">
        <div className="shimmer width-150px height-25px border-radius-default" />
        <div className="shimmer width-30px height-25px border-radius-default" />
      </div>
      {[...Array(5).keys()].map((index) => (
        <div
          key={index}
          className="shimmer margin-large margin-top-larger border-radius-default min-height-70px"
        />
      ))}
    </div>
  );
}
