import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import ProgressTrack from "../../front-end-global-components/components/ProgressTrack/ProgressTrack";
import Header from "../../front-end-global-components/components/Header/Header";
import RoundedProfilePicture from "../../front-end-global-components/components/RoundedProfilePicture/RoundedProfilePicture";
import { corporateActions } from "../../redux/sagas/corporateSaga";
import { isValidArray } from "../../utils/validators";
import ConditionalRender from "../../front-end-global-components/components/ConditionalRender/ConditionalRender";

function SelectedRequest(props) {
  const RemainingDays = (timestamp) => {
    const day = Math.round((timestamp - +new Date()) / 86400000);
    return `${day} DAY${day !== 1 && "S"} REMAINING`;
  };
  const [selectedRequest, setSelectedRequest] = useState(null);

  useEffect(() => {
    if (
      typeof props.corporate.selectedRequest !== "string" &&
      !isValidArray(props.corporate.checkups)
    ) {
      props.navigate("/");
    } else {
      setSelectedRequest(
        props.corporate.checkups?.find(
          (data) => data.requestId === props.corporate.selectedRequest
        )
      );
    }
    // eslint-disable-next-line
  }, [props.corporate.selectedRequest, props.corporate.checkups]);

  return (
    <div className="inherit-parent-height flex-place-children-page-center ">
      <div className="inherit-parent-height inherit-parent-width large-screen-bg-color large-screen-height large-screen-width large-screen-border">
        <Header
          removeResponsive={true}
          title="Checkup status"
          backButtonOnClick={() => {
            props.clearSelectedRequest();
            props.navigate("/");
          }}
          hideBackButton
          supportIconOnClick={() => {}}
        />
        <div className="remaining-body-height inherit-parent-width padding-vertical-larger font-color-secondary overflow-y-scroll hide-scrollbar">
          <ConditionalRender condition={props.corporate.loading}>
            <SelectedRequestSuspense />
          </ConditionalRender>

          <ConditionalRender condition={!props.corporate.loading}>
            <div className="padding-horizontal-larger">
              <div className="flex-justify-content-space-between">
                <div className="flex-center-children-vertically margin-top-small">
                  <div>
                    <RoundedProfilePicture src={""} size="small" />
                  </div>
                  <div className="font-family-gilroy-regular margin-left-small text-capitalize padding-default">
                    {selectedRequest?.name
                      ? selectedRequest?.name
                      : selectedRequest?.patientPhoneNumber}
                  </div>
                </div>

                <div className="flex-direction-column flex-center-children-horizontally">
                  <div
                    data-cy="status"
                    className="font-size-small text-align-right font-family-gilroy-regular"
                  >
                    {selectedRequest?.status}
                  </div>
                  <div className="font-size-smaller text-align-right font-family-gilroy-light margin-top-small">
                    {RemainingDays(selectedRequest?.expiringAt)}
                  </div>
                </div>
              </div>

              <div className="margin-top-medium">
                <div className="font-size-smaller font-family-gilroy-regular">
                  DESCRIPTION
                </div>
                <div className="font-size-large font-family-gilroy-regular margin-top-small text-capitalize">
                  {selectedRequest?.description}
                </div>
              </div>
            </div>

            <div className="background-color-grey height-2px margin-top-large" />

            <div className=" padding-horizontal-larger ">
              <ProgressTrack
                data={selectedRequest}
                userType="corporate"
                isDownloadUrlPresent={
                  props.corporate?.downloadableURLs &&
                  Object.keys(props.corporate?.downloadableURLs).includes(
                    selectedRequest?.documentId
                  )
                }
                connection={props.connection}
                pdfOnclick={() => {
                  if (
                    props.corporate?.downloadableURLs[
                      selectedRequest.documentId
                    ]
                  ) {
                    props.navigate(`/documents/${selectedRequest.documentId}`);
                  }
                }}
                options={[
                  "requestReceived",
                  "appointment",
                  "healthCheckup",
                  "report"
                ]}
              />
            </div>
          </ConditionalRender>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = function (state) {
  return {
    corporate: state.corporate,
    connection: state.connection
  };
};

const mapDispatchToProps = function () {
  return {
    clearSelectedRequest: () => corporateActions.clearSelectedRequest()
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectedRequest);

function SelectedRequestSuspense() {
  return (
    <div>
      <div className=" flex-justify-content-space-between padding-horizontal-default flex-center-children-vertically">
        <div className=" flex-center-children-vertically">
          <div className="shimmer  border-radius-100-percentage padding-small margin-default height-and-width-84px">
            <div className="padding-medium margin-default border-radius-default" />
          </div>
          <div className="shimmer width-100px height-25px margin-right-default border-radius-default " />
        </div>
        <div className="shimmer width-100px height-25px margin-left-default border-radius-default" />
      </div>
      <div className="shimmer width-100px height-16px margin-left-large border-radius-default margin-top-medium" />
      <div className="shimmer width-250px height-25px margin-left-large border-radius-default margin-top-small" />
      <div className="shimmer border-radius-default height-4px margin-top-larger" />
      <div className=" flex-justify-content-space-between padding-horizontal-large flex-center-children-vertically margin-top-large">
        <div className="shimmer width-150px height-25px border-radius-default " />
        <div className="shimmer width-30px height-25px border-radius-default" />
      </div>
      {[...Array(10).keys()].map(() => (
        <div className="shimmer margin-large border-radius-default min-height-70px" />
      ))}
    </div>
  );
}
