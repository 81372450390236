import store from "../store/store";
import { put, takeEvery, all } from "redux-saga/effects";
import { statusActions } from "./statusSaga";
import { requestCheckups } from "../../services/api";
import { getFileUrl } from "../../services/storage";
import { isOnline } from "../../utils/validators";

export const actionTypes = {
  SET_CORPORATE_CHECKUPS: "SET_CORPORATE_CHECKUPS",
  SET_SELECTED_CHECKUP: "SET_SELECTED_CHECKUP",
  CLEAR_SELECTED_REQUEST: "CLEAR_SELECTED_REQUEST",
  CREATE_HEALTH_CHECKUP: "CREATE_HEALTH_CHECKUP",
  GET_DOWNLOAD_URL_FOR_REPORT: "GET_DOWNLOAD_URL_FOR_REPORT"
};

export const corporateActions = {
  setCorporateCheckups: (data) => {
    store.dispatch({
      type: actionTypes.SET_CORPORATE_CHECKUPS,
      payload: {
        data: data
      }
    });
  },
  setSelectedRequest: (data) => {
    store.dispatch({
      type: actionTypes.SET_SELECTED_CHECKUP,
      payload: {
        data: data
      }
    });
  },
  clearSelectedRequest: () => {
    store.dispatch({
      type: actionTypes.CLEAR_SELECTED_REQUEST
    });
  },
  createHealthRequest: (data) => {
    store.dispatch({
      type: actionTypes.CREATE_HEALTH_CHECKUP,
      payload: {
        data: data
      }
    });
  },
  getReportDownloadAbleURL: (data) => {
    store.dispatch({
      type: actionTypes.GET_DOWNLOAD_URL_FOR_REPORT,
      payload: {
        data: data
      }
    });
  }
};

function* setCorporateCheckupsWorker(action) {
  try {
    yield put({
      type: "SET_CORPORATE_CHECKUPS_DATA",
      payload: {
        data: action.payload.data
      }
    });
  } catch (error) {
    yield statusActions.setErrorStatus(error);
  }
}

function* setSelectedRequestWorker(action) {
  try {
    yield setCorporateLoading(true);
    yield put({
      type: "SET_SELECTED_CHECKUP_DATA",
      payload: {
        data: action.payload.data
      }
    });
    yield setCorporateLoading(false);
  } catch (error) {
    yield statusActions.setErrorStatus(error);
    yield setCorporateLoading(false);
  }
}

function* clearSelectedRequestWorker() {
  yield put({
    type: "RESET_SELECTED_REQUEST"
  });
}

function* createHealthCheckup(action) {
  try {
    if (isOnline()) {
      yield setCorporateLoading(true);
      const branchData = store
        .getState()
        .connection.data.find(
          (connection) =>
            connection.connectionId === action.payload.data.branchId
        );
      yield requestCheckups(
        {
          id: action.payload.data.branchId,
          brandId: branchData.brandId,
          name: branchData.companyName,
          type: "corporate",
          staffId: action.payload.data.staffId
        },
        action.payload.data.phoneNumbers,
        action.payload.data.healthCheckupDetails,
        store.getState().auth.data.accessToken
      );
      yield statusActions.setSuccessStatus("Request sent successfully");
      yield setCorporateLoading(false);
    }
  } catch (error) {
    yield statusActions.setErrorStatus(error);
    yield setCorporateLoading(false);
  }
}

function* getUploadURLForReportWorker(action) {
  try {
    if (isOnline()) {
      let URL;
      const fileName = "default.json";
      const fileType = "DiagnosticReport";
      const documentId = action.payload.data.documentId;
      const clinicId = action.payload.data.clinicId;
      const patientDemographicDocumentId = action.payload.data.patientId;
      URL = yield getFileUrl(
        action.payload.data.url === undefined
          ? `gs://dev-ninto-documents-storage-1/documents/${clinicId}/${patientDemographicDocumentId}/${fileType}/${documentId}/${fileName}`
          : action.payload.data.url
      );

      yield put({
        type: "SET_CHECKUP_DOWNLOADABLE_URL",
        payload: {
          data: {
            ...(typeof URL === "string" ? { [documentId]: URL } : {})
          }
        }
      });
    }
  } catch (error) {
    yield statusActions.setErrorStatus(error);
  }
}

export function* corporateWatcher() {
  yield all([
    takeEvery("SET_CORPORATE_CHECKUPS", setCorporateCheckupsWorker),
    takeEvery("SET_SELECTED_CHECKUP", setSelectedRequestWorker),
    takeEvery("CLEAR_SELECTED_REQUEST", clearSelectedRequestWorker),
    takeEvery("CREATE_HEALTH_CHECKUP", createHealthCheckup),
    takeEvery("GET_DOWNLOAD_URL_FOR_REPORT", getUploadURLForReportWorker)
  ]);
}

function* setCorporateLoading(bool) {
  yield put({
    type: "SET_CORPORATE_LOADING",
    payload: {
      loading: bool
    }
  });
}
