import React, { useEffect, useRef, useState } from "react";
import "./SliderButton.css";

export default function SliderButton(props) {
  const slider = useRef();
  const container = useRef();
  const [drag, setDrag] = useState(false);
  const [isTouchDevice, setIsTouchDevice] = useState(false);
  const [sliderLeft, setSliderLeft] = useState(0);
  const [containerWidth, setContainerWidth] = useState(0);
  const [startX, setStartX] = useState(0);
  const [completed, setCompleted] = useState(false);

  useEffect(() => {
    if ("ontouchstart" in window) {
      setIsTouchDevice(true);
    } else {
      setIsTouchDevice(false);
    }
  }, []);

  const onDrag = (event) => {
    if (completed) return;
    if (drag) {
      if (isTouchDevice) {
        setSliderLeft(
          Math.min(
            Math.max(0, event.touches && event.touches[0].clientX - startX),
            containerWidth
          )
        );
      } else {
        setSliderLeft(
          Math.min(Math.max(0, event.clientX - startX), containerWidth)
        );
      }
      updateSliderStyle();
    }
  };

  const stopDrag = (event) => {
    if (completed) return;
    if (drag) {
      setDrag(false);
      if (sliderLeft > (containerWidth * 0.9) / 2) {
        setSliderLeft(containerWidth);
        onSuccess();
      }
      updateSliderStyle();
    }
  };

  const startDrag = (event) => {
    if (completed) return;
    setDrag(true);
    if (isTouchDevice) {
      setStartX(event.touches && event.touches[0].clientX);
    } else {
      setStartX(event.clientX);
    }
  };

  const updateSliderStyle = () => {
    if (completed) return;
    slider.current.style.left = sliderLeft + 50 + "px";
  };

  const onSuccess = () => {
    container.current.style.width = container.current.clientWidth + "px";
    setCompleted(true);
  };

  useEffect(() => {
    setContainerWidth(container.current.clientWidth - 50);
  }, []);

  useEffect(() => {
    if (completed === false) {
      setSliderLeft(0);
      updateSliderStyle();
    }
    props.completed(completed);
    // eslint-disable-next-line
  }, [completed]);

  useEffect(() => {
    setCompleted(props.setCompleted);
  }, [props.setCompleted]);

  return (
    <div className="swipeButton inherit-parent-width height-50-px ">
      <div
        className={`inherit-parent-width inherit-parent-height border-radius-default overflow-hidden background-color-f1f1f1 ${
          completed ? "swipeButtonLocked" : ""
        }`}
        ref={container}
      >
        <div
          className="slider height-50-px inherit-parent-width"
          ref={slider}
          onMouseDown={(event) => {
            startDrag(event);
          }}
          onMouseLeave={(event) => {
            stopDrag(event);
          }}
          style={{ background: "#99D999" }}
          onTouchStart={(event) => {
            startDrag(event);
          }}
          onTouchEnd={(event) => {
            stopDrag(event);
          }}
          onTouchMove={(event) => {
            if (drag) {
              onDrag(event);
            }
          }}
          onMouseMove={(event) => {
            if (drag) {
              onDrag(event);
            }
          }}
        >
          <div className="sliderText">{`${
            completed ? "Completed" : "Completing"
          }`}</div>
          <div className="display-flex">
            <div className="arrowIcon right-20-px" />
            <div className="arrowIcon right-30-px" />
          </div>

          <div className="sliderCircle" style={{ background: "#00A000" }} />
        </div>
        <div className="display-flex inherit-parent-height inherit-parent-width flex-center-children-vertically flex-center-children-horizontally">
          {"Check up complete"}
        </div>
      </div>
    </div>
  );
}
