import React from "react";
import RoundedProfilePicture from "../../front-end-global-components/components/RoundedProfilePicture/RoundedProfilePicture";
import add from "../../front-end-global-components/assets/add_grey.svg";
import ConditionalRender from "../../front-end-global-components/components/ConditionalRender/ConditionalRender";
import { ArrowIcon, TickIcon } from "../../assets/assets";

export default function ProfileCards(props) {
  return (
    <>
      {!props.type && (
        <div
          data-cy={`${
            props.data?.connectionId
              ? props.data.connectionId + "-card"
              : props.data.name + "-card"
          }`}
          className={`inherit-parent-width flex-justify-content-space-between margin-bottom-large  border-radius-default cursor-pointer`}
          onClick={props.onCardClick}
        >
          <div className="flex-center-children-vertically">
            <RoundedProfilePicture
              size="medium"
              userType="corporate"
              removeResponsive={true}
              src={
                props.connection?.corporateBrandLogo && props.data?.documentId
                  ? props.connection?.corporateBrandLogo[props.data?.documentId]
                  : props.connection?.corporateBrandLogo[props.data?.brandId]
              }
            />
            <div className="padding-left-large">
              <div className=" font-size-large padding-right-default">
                {props.data?.companyName
                  ? props.data.companyName
                  : props.data.name}
              </div>
              <div className=" font-size-small">{props.data.locality}</div>
            </div>
          </div>
          <ConditionalRender condition={props.selected === true}>
            <div className="padding-right-medium flex-center-children-vertically">
              <TickIcon />
            </div>
          </ConditionalRender>
          <ConditionalRender condition={props.selected === false}>
            <div className="padding-right-medium flex-center-children-vertically">
              <ArrowIcon />
            </div>
          </ConditionalRender>
        </div>
      )}

      {props.type === "add" && (
        <div
          data-cy="add-card"
          className="flex-justify-content-space-between flex-center-children-vertically cursor-pointer"
          onClick={props.addOnClick}
        >
          <div className=" display-flex flex-center-children-vertically ">
            <div className="background-color-lite-grey border-radius-100-percentage height-and-width-44px flex-place-children-page-center">
              <img src={add} alt="add" />
            </div>
            <div className="font-size-large padding-left-large">
              {props.text}
            </div>
          </div>
          <div
            className={`padding-right-medium flex-center-children-vertically`}
          >
            <ArrowIcon />
          </div>
        </div>
      )}
    </>
  );
}
