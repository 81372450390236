import React, { useState, useRef, useEffect } from "react";
import "./ChipInputBox.css";
import { isValidArray, isValidString } from "../../services/validators";
import inputValidIcon from "../../assets/inputValid.svg";
import PropTypes from "prop-types";

function ChipInputBox(props) {
  const [chips, setChips] = useState([]);
  const [selectedChipIndex, setSelectedChipIndex] = useState(null);
  const selectedChipIndexRef = useRef(null);
  const label = useRef(null);
  const inputRef = useRef(null);
  const chipsContainerRef = useRef(null);
  const isAddedListener = useRef(false);
  const [inputValue, setInputValue] = useState("");
  const previousLoadingState = useRef(false);
  const [complete, setComplete] = useState(false);

  useEffect(() => {
    if (props.chips) {
      setChips([...props.chips]);
    }
  }, [props.chips]);

  useEffect(() => {
    if (previousLoadingState.current === true && props.loading === false) {
      setComplete(true);
    } else {
      if (complete) setComplete(false);
    }
    previousLoadingState.current = props.loading;
    // eslint-disable-next-line
  }, [props.loading]);

  useEffect(() => {
    const setIndex = () => {
      if (selectedChipIndexRef.current !== null) {
        setSelectedChipIndex(null);
      }
    };
    function handleClickOutside(event) {
      if (
        !(
          chipsContainerRef.current &&
          chipsContainerRef.current.contains(event.target)
        )
      ) {
        setIndex();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    isAddedListener.current = true;
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chipsContainerRef]);

  const addChip = (newArray) => {
    let result = true;
    if (isValidString(inputValue) && isValidArray(props.chips)) {
      const newLowerCasedChips = props.chips.map((val) => {
        return `${val}`.toLowerCase();
      });
      //don`t allow
      result = !newLowerCasedChips.includes(`${inputValue}`.toLowerCase());
    } else if (!isValidArray(props.chips)) {
      result = true;
    } else {
      result = false;
    }

    if (result === true) {
      props.onChipAdded && props.onChipAdded([...newArray]);
    }
    setInputValue("");
  };

  const InputSuffixIcon = () => {
    if (props.loading === true) {
      return (
        <div
          className="circular-loader"
          data-cy={`${props.name}-chip-input-box-loader-icon`}
        />
      );
    } else if (isValidArray(chips) && complete === true) {
      return (
        <img
          className={`height-width-1em ${props.iconClassNames}`}
          src={inputValidIcon}
          alt="inputValidIcon"
          data-cy={`${props.name}-input-box-complete-icon`}
        />
      );
    } else {
      return null;
    }
  };

  return (
    <div
      className={`inherit-parent-width  ${
        props.className ? props.className : ``
      }`}
    >
      <div
        className={` position-relative min-height-48px inherit-parent-width   ${
          props.disabled === true ? " background-color-grey " : ""
        } ${props.chips ? " padding-top-medium" : ""} `}
        ref={chipsContainerRef}
      >
        <section>
          {isValidArray(chips) && (
            <section className="max-height-100px flex-direction-row gap-0-point-5em margin-top-small flex-wrap overflow-auto">
              {chips.map((chipData, index) =>
                typeof chipData === "string" ? (
                  <div
                    data-cy={`${chipData}-chip${
                      selectedChipIndex === index ? "-selected" : ""
                    }`}
                    className={`text-transform-capitalize text-overflow-ellipsis padding-small border-2px-lite-grey text-transform-uppercase border-radius-default font-size-small font-family-gilroy-regular font-color-secondary ${
                      selectedChipIndex === index
                        ? "background-color-lite-grey"
                        : ""
                    } flex-center-children-vertically `}
                    key={`chip-data-${index}`}
                    onClick={() => {
                      if (selectedChipIndex === index) {
                        let newArray = chips;
                        newArray.splice(index, 1);
                        props.onChipAdded && props.onChipAdded([...newArray]);
                        setSelectedChipIndex(null);
                        selectedChipIndexRef.current = null;
                      } else {
                        selectedChipIndexRef.current = index;
                        setSelectedChipIndex(index);
                      }
                    }}
                  >
                    <span>{chipData}</span>
                    {selectedChipIndex === index && (
                      <svg
                        className="margin-left-default height-width-0-point-5em"
                        width="8"
                        height="8"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1.72153 0.295367C1.3277 -0.0984556 0.68919 -0.0984557 0.295367 0.295367C-0.0984556 0.68919 -0.0984557 1.3277 0.295367 1.72153L3.62307 5.04923C4.14817 5.57432 4.14817 6.42567 3.62307 6.95077L0.295367 10.2785C-0.0984556 10.6723 -0.0984556 11.3108 0.295367 11.7046C0.68919 12.0985 1.3277 12.0985 1.72153 11.7046L5.04923 8.37693C5.57432 7.85183 6.42567 7.85183 6.95077 8.37693L10.2785 11.7046C10.6723 12.0985 11.3108 12.0985 11.7046 11.7046C12.0985 11.3108 12.0985 10.6723 11.7046 10.2785L8.37693 6.95077C7.85183 6.42567 7.85183 5.57432 8.37693 5.04923L11.7046 1.72153C12.0985 1.3277 12.0985 0.68919 11.7046 0.295368C11.3108 -0.0984553 10.6723 -0.0984556 10.2785 0.295367L6.95077 3.62307C6.42567 4.14817 5.57432 4.14817 5.04923 3.62307L1.72153 0.295367Z"
                          fill="#959595"
                        />
                      </svg>
                    )}
                  </div>
                ) : (
                  <div
                    data-cy={`${
                      chipData.display ? chipData.display : chipData.phoneNumber
                    }-chip${
                      selectedChipIndex === chipData.id ? "-selected" : ""
                    }`}
                    className={`text-transform-capitalize text-overflow-ellipsis padding-small border-2px-lite-grey text-transform-uppercase border-radius-default font-size-small font-family-gilroy-regular font-color-secondary ${
                      selectedChipIndex === index ||
                      selectedChipIndex === chipData.id
                        ? "background-color-lite-grey"
                        : ""
                    } flex-center-children-vertically `}
                    key={`chip-data-${chipData.id}`}
                    onClick={() => {
                      if (selectedChipIndex === chipData.id) {
                        props.onChipDelete(
                          chipData.display
                            ? chipData.display
                            : chipData.phoneNumber
                        );
                      } else {
                        selectedChipIndexRef.current = chipData.id;
                        setSelectedChipIndex(chipData.id);
                      }
                    }}
                  >
                    <span>
                      {chipData.display
                        ? chipData.display
                        : chipData.phoneNumber}
                    </span>
                    {(selectedChipIndex === index ||
                      selectedChipIndex === chipData.id) && (
                      <svg
                        className="margin-left-default height-width-0-point-5em"
                        width="8"
                        height="8"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1.72153 0.295367C1.3277 -0.0984556 0.68919 -0.0984557 0.295367 0.295367C-0.0984556 0.68919 -0.0984557 1.3277 0.295367 1.72153L3.62307 5.04923C4.14817 5.57432 4.14817 6.42567 3.62307 6.95077L0.295367 10.2785C-0.0984556 10.6723 -0.0984556 11.3108 0.295367 11.7046C0.68919 12.0985 1.3277 12.0985 1.72153 11.7046L5.04923 8.37693C5.57432 7.85183 6.42567 7.85183 6.95077 8.37693L10.2785 11.7046C10.6723 12.0985 11.3108 12.0985 11.7046 11.7046C12.0985 11.3108 12.0985 10.6723 11.7046 10.2785L8.37693 6.95077C7.85183 6.42567 7.85183 5.57432 8.37693 5.04923L11.7046 1.72153C12.0985 1.3277 12.0985 0.68919 11.7046 0.295368C11.3108 -0.0984553 10.6723 -0.0984556 10.2785 0.295367L6.95077 3.62307C6.42567 4.14817 5.57432 4.14817 5.04923 3.62307L1.72153 0.295367Z"
                          fill="#959595"
                        />
                      </svg>
                    )}
                  </div>
                )
              )}
            </section>
          )}
        </section>
        <div className="position-relative font-size-medium font-family-gilroy-medium inherit-parent-width">
          <input
            data-cy={
              props["data-cy"]
                ? props["data-cy"]
                : `${props.name}-chip-input-box`
            }
            maxLength={props.maxLength}
            readOnly={props.disabled}
            ref={inputRef}
            value={inputValue}
            onClick={props.reDirectOnClick ? props.reDirectOnClick : () => {}}
            className={`chip-input-box background-transparent max-width-100-percentage inherit-parent-width font-family-gilroy-medium font-size-medium font-color-secondary padding-top-default padding-bottom-default display-block  chip-input-box-border-bottom-default chip-input-box-disabled-padding-left-5px padding-right-2em border-radius-0`}
            autoComplete={"off"}
            onFocus={() => {
              if (props.disabled === true) return;
              if (!props.reDirectOnClick) return;
              label.current && label.current.classList.add("active");
              inputRef.current && inputRef.current.classList.add("active");
            }}
            onBlur={(_) => {
              if (!isValidArray(chips) && !inputValue) {
                label.current && label.current.classList.remove("active");
              }
              inputRef.current && inputRef.current.classList.remove("active");
            }}
            onKeyDown={(event) => {
              event.stopPropagation();
              if (event.key === "Enter" && inputValue.trim().length === 0) {
                event.preventDefault();
              }
              if (
                event.key === "Enter" &&
                inputValue &&
                inputValue.trim().length !== 0
              ) {
                if (inputValue && inputValue.trim().length !== 0) {
                  addChip([...chips, inputValue]);
                }
              }
            }}
            type="text"
            name={`${props.name}-input`}
            onChange={(event) => {
              setInputValue(event.target.value);
            }}
          />
          <span className="chip-input-box-border-bottom"></span>
          <div
            data-cy={props["add-data-cy"] ? props["add-data-cy"] : "add-icon"}
            className={` position-absolute flex-align-items-center flex-justify-content-center min-width-1em top-50-percentage transform-translateY-25-percentage right-0 bottom-0`}
          >
            <InputSuffixIcon />
            <svg
              onClick={
                props.reDirectOnClick
                  ? props.reDirectOnClick
                  : () => {
                      if (isValidString(inputValue)) {
                        // setChips([...chips,inputValue])
                        addChip([...chips, inputValue]);
                      }
                      inputRef.current.focus();
                    }
              }
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="margin-left-small"
            >
              <path
                d="M9 1C9 0.447715 8.55229 0 8 0C7.44772 0 7 0.447715 7 1V5.66667C7 6.40305 6.40305 7 5.66667 7H1C0.447715 7 0 7.44772 0 8C0 8.55229 0.447715 9 1 9H5.66667C6.40305 9 7 9.59695 7 10.3333V15C7 15.5523 7.44772 16 8 16C8.55229 16 9 15.5523 9 15V10.3333C9 9.59695 9.59695 9 10.3333 9H15C15.5523 9 16 8.55229 16 8C16 7.44772 15.5523 7 15 7H10.3333C9.59695 7 9 6.40305 9 5.66667V1Z"
                fill="#959595"
              />
            </svg>
          </div>
        </div>
        <label
          data-cy={`label-${props.name}`}
          htmlFor={props.name}
          ref={label}
          className={` font-color-secondary ${
            props.removeResponsive ? "" : "adaptive-chip-input-box-label"
          } chip-input-box-label  position-absolute font-family-gilroy-medium font-size-medium ${
            props.labelClassName ? props.labelClassName : ""
          } ${
            inputRef.current === document.activeElement ||
            isValidArray(chips) ||
            inputValue ||
            props.alwaysActive
              ? "active"
              : ""
          } ${props.disabled ? " disabled " : ""}
							`}
        >
          {props.label}
        </label>
      </div>
    </div>
  );
}

ChipInputBox.propTypes = {
  /**
   * default chips values to be shown when rendering
   */
  chips: PropTypes.array,
  /**
   * loading state for the chip input box
   */
  loading: PropTypes.bool,
  /**
   * chip input box name
   */
  name: PropTypes.string.isRequired,
  /**
   * loading icon className
   */
  iconClassNames: PropTypes.string,
  /**
   * disabled state for input
   */
  disabled: PropTypes.bool,
  /**
   * class name for component wrapper
   */
  className: PropTypes.string,
  /**
   * onChipAdded is function prop which gives chips as arguments
   * props.onChipAdded([...newArray])
   */
  onChipAdded: PropTypes.func,
  /**
   * class name for input label
   */
  labelClassName: PropTypes.string,
  /**
   * optional prop which used to set label active
   */
  alwaysActive: PropTypes.bool,
  /**
   * label for the chip input box component
   */
  label: PropTypes.string
};

//Default props
ChipInputBox.defaultProps = {
  chips: [],
  loading: false,
  name: "chipInputBox",
  iconClassNames: "",
  disabled: false,
  className: "",
  onChipAdded: undefined,
  labelClassName: "",
  alwaysActive: false,
  label: ""
};

export default ChipInputBox;
