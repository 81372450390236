import {
  doc,
  setDoc,
  addDoc,
  query,
  where,
  collection,
  onSnapshot,
  getDoc,
  collectionGroup,
  getDocs,
  deleteDoc,
  updateDoc,
  orderBy
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { statusActions } from "../redux/sagas/statusSaga";
import { profileActions } from "../redux/sagas/profileSaga";
import { isValidArray, isValidObject } from "../utils/validators";
import { getCurrentUser } from "./authentication";
import { bucketNames } from "./storage";
import { connectionActions } from "../redux/sagas/connectionSaga";
import { database } from "./firebase";
import { corporateActions } from "../redux/sagas/corporateSaga";
import { throwError } from "./error";

export async function createProfile(userName) {
  const currentUser = getCurrentUser();
  const { id: documentId } = await addDoc(
    collection(database, "corporateStaffs"),
    {
      phoneNumber: currentUser.phoneNumber,
      uid: currentUser.uid,
      ...(userName && !userName.includes("+91") ? { fullName: userName } : {})
    }
  );
  if (userName && userName.includes("+91")) {
    console.error("Name is not valid in create profile");
  }
  const privateRef = doc(
    database,
    "corporateStaffs",
    documentId,
    "corporateStaffsPrivate",
    "profile"
  );
  await setDoc(
    privateRef,
    {
      fullName: JSON.parse(currentUser.displayName).corporateStaff,
      phoneNumber: currentUser.phoneNumber,
      uid: currentUser.uid
    },
    { merge: true }
  );
}

export async function editProfile(data, profileId, connectionIds) {
  const rootRef = doc(database, "corporateStaffs", profileId);
  if (data.userName.includes("+91")) {
    throw throwError("custom", "Name is Not valid from edit profile");
  }
  await updateDoc(rootRef, {
    ...(data.userName ? { fullName: data.userName } : {})
  });

  const privateRef = doc(
    database,
    "corporateStaffs",
    profileId,
    "corporateStaffsPrivate",
    "profile"
  );

  await updateDoc(privateRef, {
    ...(data.userName ? { fullName: data.userName } : {})
  });

  if (isValidArray(connectionIds)) {
    connectionIds.forEach((element) => {
      const connectionsRef = doc(
        database,
        "clinicStaffs",
        profileId,
        "connections",
        element
      );
      updateDoc(connectionsRef, {
        ...(data.userName ? { fullName: data.userName } : {})
      });
    });
  }
}

export async function addConnectionsToProfile(
  documentId,
  data,
  profileId,
  brandId
) {
  const currentUser = getCurrentUser();
  const connectionsRef = doc(
    database,
    "corporateStaffs",
    profileId,
    "connections",
    documentId
  );
  await setDoc(
    connectionsRef,
    {
      connectedAt: +new Date(),
      ...(data.companyName ? { companyName: data.companyName } : {}),
      ...(data.locality ? { locality: data.locality } : {}),
      phoneNumber: currentUser.phoneNumber,
      brandId: brandId,
      userType: "owner",
      profileId: profileId,
      connectionId: documentId
    },
    { merge: true }
  );
}

export async function createCorporateProfile(data, brandId) {
  const { id: documentId } = await addDoc(collection(database, "corporates"), {
    brandId: brandId
  });
  const publicRef = doc(
    database,
    "corporates",
    documentId,
    "corporatesPublic",
    "profile"
  );
  await setDoc(
    publicRef,
    {
      ...(data.companyName ? { corporateName: data.companyName } : {}),
      ...(data.locality ? { locality: data.locality } : {}),
      ...(data.phoneNumber ? { phoneNumber: data.phoneNumber } : {}),
      ...(data.email ? { email: data.email } : {}),
      ...(data.address ? { address: data.address } : {}),
      ...(data.website ? { website: data.website } : {}),
      ...(data.city ? { city: data.city } : {}),
      ...(data.state ? { state: data.state } : {}),
      ...(data.pincode ? { pincode: data.pincode } : {}),
      brandId: brandId,
      corporateId: documentId
    },
    { merge: true }
  );

  const privateRef = doc(
    database,
    "corporates",
    documentId,
    "corporatesPrivate",
    "profile"
  );
  await setDoc(
    privateRef,
    {
      connectedPhoneNumbers: [],
      brandId: brandId,
      corporateId: documentId
    },
    { merge: true }
  );
  return documentId;
}

export async function createCorporateBrand(data, profileId) {
  const currentUser = getCurrentUser();
  const { id: documentId } = await addDoc(
    collection(database, "corporateBrands"),
    {
      ...(data.companyName ? { name: data.companyName } : {}),
      ...(data.email ? { email: data.email } : {}),
      ...(data.website ? { website: data.website } : {}),
      createdBy: {
        uid: currentUser.uid,
        profileId: profileId
      }
    }
  );

  const profileRef = doc(database, "corporateBrands", documentId);
  await updateDoc(profileRef, {
    ...(data.companyLogo
      ? {
          logo: `gs://${bucketNames.nintoProfilePictures}/corporateBrands/${documentId}.png`
        }
      : {}),
    processing: true
  });

  return documentId;
}

export function useProfileListener(props) {
  const [profileListener, setProfileListener] = useState({
    listener: null
  });

  const subscribeToProfile = (phoneNumber) => {
    if (phoneNumber) {
      const profileQuery = query(
        collection(database, "corporateStaffs"),
        where("phoneNumber", "==", phoneNumber)
      );

      return onSnapshot(
        profileQuery,
        (dataSnapshot) => {
          let profiles = {};
          dataSnapshot.forEach((doc) => {
            profiles[doc.id] = {
              ...doc.data()
            };
          });
          profileActions.setAccountProfile(profiles);
        },
        (error) => {
          // console.log("profile");
          statusActions.setErrorStatus(error);
        }
      );
    } else {
      return;
    }
  };
  useEffect(() => {
    if (
      props.isAuthed === true &&
      typeof props.phoneNumber === "string" &&
      typeof props.uid === "string" &&
      profileListener.listener === null
    ) {
      setProfileListener({
        listener: subscribeToProfile(props.phoneNumber)
      });
    } else if (
      props.isAuthed === false &&
      typeof props.phoneNumber !== "string" &&
      typeof props.uid !== "string" &&
      typeof profileListener.listener === "function"
    ) {
      profileListener.listener();
      setProfileListener({
        listener: null
      });
    }
    // eslint-disable-next-line
  }, [props.isAuthed, props.phoneNumber, props.uid]);
}

//connection Listener

export async function useConnectionsListener(props) {
  const [connectionsListener, setConnectionsListener] = useState({
    listener: null
  });

  const subscribeToDocuments = (phoneNumber, uid) => {
    if (phoneNumber) {
      const connectionsQuery = query(
        collection(database, "corporateStaffs", uid, "connections"),
        where("phoneNumber", "==", phoneNumber)
      );
      return onSnapshot(
        connectionsQuery,
        (querySnapshot) => {
          let connections = [];
          let brandData = [];
          querySnapshot.forEach((doc) => {
            if (isValidObject(doc.data())) {
              brandData.push(doc.data().brandId);
              connections.push({ connectionId: doc.id, ...doc.data() });
            }
          });
          connectionActions.getBrandData(brandData);
          connectionActions.setConnections(connections);
        },
        (error) => {
          statusActions.setErrorStatus(error);
        }
      );
    } else {
      return;
    }
  };
  useEffect(() => {
    if (props.isAuthed === true && props.uid && props.phoneNumber) {
      setConnectionsListener({
        listener: subscribeToDocuments(props.phoneNumber, props.uid)
      });
    } else if (
      props.isAuthed === false &&
      typeof connectionsListener.listener === "function"
    ) {
      connectionsListener.listener();
      setConnectionsListener({
        listener: null
      });
    }
    // eslint-disable-next-line
  }, [props.uid, props.isAuthed, props.phoneNumber]);
}

// checkups listener
export function useCheckupsListenerForOwner(props) {
  const [checkupsListener, setCheckupsListener] = useState({
    listener: null
  });

  const subscribeToCheckup = (brandIds) => {
    if (isValidArray(brandIds)) {
      const checkupQuery = query(
        collection(database, "corporateCheckups"),
        where("corporateBrandId", "in", brandIds),
        orderBy("requestedAt", "desc")
      );

      return onSnapshot(
        checkupQuery,
        (dataSnapshot) => {
          let checkups = [];
          dataSnapshot.forEach((doc) => {
            checkups.push({ ...doc.data(), requestId: doc.id });
            if (doc.data().documentId) {
              corporateActions.getReportDownloadAbleURL({
                documentId: doc.data().documentId,
                url: doc.data().url,
                clinicId: doc.data().clinicId,
                patientId: doc.data().patientId
              });
            }
          });
          corporateActions.setCorporateCheckups(checkups);
        },
        (error) => {
          // console.log("checkup owner");
          statusActions.setErrorStatus(error);
        }
      );
    }
  };
  useEffect(() => {
    if (
      props.isAuthed === true &&
      isValidArray(props.brandIds) &&
      checkupsListener.listener === null
    ) {
      setCheckupsListener({
        listener: subscribeToCheckup(props.brandIds)
      });
    } else if (
      props.isAuthed === false &&
      typeof checkupsListener.listener === "function"
    ) {
      checkupsListener.listener();
      setCheckupsListener({
        listener: null
      });
    }
    // eslint-disable-next-line
  }, [props.isAuthed, props.brandIds]);
}

export function useCheckupsListenerForStaffs(props) {
  const [checkupsListener, setCheckupsListener] = useState({
    listener: null
  });

  const subscribeToCheckup = (connectionIds) => {
    if (isValidArray(connectionIds)) {
      const profileQuery = query(
        collection(database, "corporateCheckups"),
        where("corporateId", "in", connectionIds),
        orderBy("requestedAt", "desc")
      );

      return onSnapshot(
        profileQuery,
        (dataSnapshot) => {
          let checkups = [];
          dataSnapshot.forEach((doc) => {
            checkups.push({ ...doc.data(), requestId: doc.id });
            if (doc.data().documentId) {
              corporateActions.getReportDownloadAbleURL({
                documentId: doc.data().documentId,
                url: doc.data().url,
                clinicId: doc.data().clinicId,
                patientId: doc.data().patientId
              });
            }
          });
          corporateActions.setCorporateCheckups(checkups);
        },
        (error) => {
          // console.log("checkup staff");
          statusActions.setErrorStatus(error);
        }
      );
    }
  };
  useEffect(() => {
    if (
      props.isAuthed === true &&
      isValidArray(props.connectionIds) &&
      checkupsListener.listener === null
    ) {
      setCheckupsListener({
        listener: subscribeToCheckup(props.connectionIds)
      });
    } else if (
      props.isAuthed === false &&
      typeof checkupsListener.listener === "function"
    ) {
      checkupsListener.listener();
      setCheckupsListener({
        listener: null
      });
    }
    // eslint-disable-next-line
  }, [props.isAuthed, props.connectionIds]);
}

export async function getBrandDataById(brandId) {
  const querySnapshot = await getDoc(doc(database, "corporateBrands", brandId));
  return querySnapshot.data();
}

export async function getConnectedStaffs(branchId) {
  const getBrandData = await getDocs(
    query(
      collectionGroup(database, "connections"),
      where("connectionId", "==", branchId)
    )
  );
  const staffs = {};
  getBrandData.forEach((doc) => {
    staffs[doc.data().profileId] = {
      ...doc.data()
    };
  });
  return staffs;
}

export async function createNewStaff(creatorId, branchData, data) {
  const { id: profileId } = await addDoc(
    collection(database, "corporateStaffs"),
    {
      phoneNumber: data.phoneNumber,
      createdBy: creatorId,
      connectionWith: branchData.connectionId
    }
  );
  await setDoc(
    doc(
      database,
      "corporateStaffs",
      profileId,
      "corporateStaffsPrivate",
      "profile"
    ),
    {
      phoneNumber: data.phoneNumber,
      fullName: data.name
    }
  );
  await setDoc(
    doc(
      database,
      "corporateStaffs",
      profileId,
      "connections",
      branchData.connectionId
    ),
    {
      connectionId: branchData.connectionId,
      brandId: branchData.brandId,
      userType: data.userType,
      companyName: branchData.companyName,
      profileId: profileId,
      phoneNumber: data.phoneNumber,
      staffName: data.name
    }
  );
  return {
    connectionId: branchData.connectionId,
    brandId: branchData.brandId,
    userType: data.userType,
    companyName: branchData.companyName,
    profileId: profileId,
    staffName: data.name
  };
}

export async function removeStaff(branchId, profileId) {
  await deleteDoc(
    doc(database, "corporateStaffs", profileId, "connections", branchId)
  );
}

export async function getClinicBrands() {
  const getBrandData = await getDocs(
    query(collection(database, "clinicBrands"), where("verified", "==", true))
  );
  const brands = {};
  getBrandData.forEach((doc) => {
    brands[doc.id] = { ...doc.data(), documentId: doc.id };
  });
  return brands;
}

export async function clinicPartneringRequest(
  corporateBrandId,
  clinicBrandId,
  corporateName,
  clinicName
) {
  const { id: corporatePackageId } = await addDoc(
    collection(database, "corporatePackages"),
    {
      corporateBrandId: corporateBrandId,
      clinicBrandId: clinicBrandId,
      corporateName: corporateName,
      clinicName: clinicName,
      expiringAt: +new Date(
        new Date().setFullYear(new Date().getFullYear() + 1)
      )
    }
  );
  return {
    [corporatePackageId]: {
      corporateBrandId: corporateBrandId,
      clinicBrandId: clinicBrandId,
      corporateName: corporateName,
      clinicName: clinicName,
      documentId: corporatePackageId,
      expiringAt: +new Date(
        new Date().setFullYear(new Date().getFullYear() + 1)
      )
    }
  };
}

export async function getCorporatePackages(corporateBrandIds) {
  const getBrandData = await getDocs(
    query(
      collection(database, "corporatePackages"),
      where("corporateBrandId", "in", corporateBrandIds)
    )
  );
  const corporatePackages = {};
  getBrandData.forEach((doc) => {
    corporatePackages[doc.id] = { ...doc.data(), documentId: doc.id };
  });
  return corporatePackages;
}

export async function removeCorporatePackage(corporatePackageId) {
  await deleteDoc(doc(database, "corporatePackages", corporatePackageId));
}

export async function createHealthCheckups(
  patientDetails,
  corporateDetails,
  checkupDetails
) {
  // eslint-disable-next-line no-unused-vars
  for (const [index, value] of patientDetails.entries()) {
    const { id: patientId } = await addDoc(
      collection(database, "patient-demographics"),
      {
        fullName: value.name,
        phoneNumber: value.phoneNumber
      }
    );
    await addDoc(collection(database, "corporateCheckups"), {
      name: value.name,
      patientId: patientId,
      patientPhoneNumber: value.phoneNumber,
      corporateName: corporateDetails.name,
      status: "REQUESTED",
      corporateId: corporateDetails.id,
      corporateBrandId: corporateDetails.brandId,
      requestedAt: +new Date(),
      expiringAt: +new Date(new Date().getTime() + 30 * 24 * 60 * 60 * 1000),
      description: checkupDetails.description,
      shareReportWithUser: checkupDetails.shareReportWithUser
    });
  }
}
